.validationflow .react-flow__node {
  /* width: 3%; */
  border-radius: 5px;
  color: #000;
  text-align: center;
  font-size: 12px;
}
@media screen and (min-width:720px){
  .validationflow .react-flow__node {
    width: 5%;
  }
}
@media screen and (min-width:900px){
  .validationflow .react-flow__node {
    width: 5%;
  }
}
@media screen and (min-width:1080px){
  .validationflow .react-flow__node {
    width: 3%;
  }
}

.validationflow .react-flow__node-customnode {
  background: #e6e6e9;
  border: 1px solid #ddd;
}

.react-flow__node-custominput .react-flow__handle {
  background: #e6e6e9;
}

.validationflow .react-flow__node-custominput {
  background: #fff;
}

.validationflow .react-flow__handle-connecting {
  background: #ff6060;
}

.validationflow .react-flow__handle-valid {
  background: #55dd99;
}

.node__image {
  padding: 10px;
}
.node__image--executado {
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #60665f;
  background: #55dd99;
 }
 .node__image--cancelado {
  padding: 10px;
  border: 2px solid #60665f;
  border-radius: 5px;
  background: #ff6060;
 }
 .node__image--progress {
  padding: 10px;
  border: 2px solid #60665f;
  border-radius: 5px;
  background: #55dd99;
  animation-name: stretch;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
@keyframes stretch {
  0% {
    /* transform: scale(0.8); */
    background-color: #55dd99;
    /* border-radius: 100%; */
  }

  50% {
    background-color: #ffb060;
  }

  100% {
    /* transform: scale(1); */
    background-color: #55dd99;
  }
}

.react-flow__node{
 cursor: pointer;
}
.node__group {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 0;
  z-index: -50;
 cursor: not-allowed;
}

